import styled from 'styled-components'
import { mq } from '../../styles/vars/media-queries.style'
import { PageTransitionLinkMain } from '../PageTransitionLink/index.style'

export const FooterMain = styled.footer``

export const FooterRow = styled.div`
  ${mq.tabletP} {
    display: flex;
    align-items: ${props => (props.center ? 'center' : 'flex-end')};
  }
`

export const FooterEmail = styled.p`
  font-size: 2.1rem;
  line-height: 1.24;
  letter-spacing: 0.01em;
`

export const FooterSocial = styled.div`
  display: flex;
  align-items: center;

  ${mq.tabletPMax} {
    margin-top: 1.6rem;
  }

  ${mq.tabletP} {
    margin-left: 2.6rem;
  }
`

export const FooterSocialLink = styled.a`
  display: block;
  border: none;

  & + & {
    margin-left: 4.2rem;
  }
`

export const FooterLinks = styled.div`
  display: flex;
  align-items: center;

  ${mq.tabletPMax} {
    margin-top: 1.6rem;
  }

  ${mq.tabletP} {
    margin-left: 2rem;
  }
`

export const FooterLinkText = styled.div`
  & + & {
    margin-left: 2.1rem;
  }

  ${PageTransitionLinkMain} {
    display: block;
    text-decoration: underline;
  }
`
