import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Container from '../_layouts/Container'
import PageTransitionLink from '../PageTransitionLink'
import Spacer from '../Spacer'
import Grid from '../_layouts/Grid'
import GridItem from '../_layouts/GridItem'
import IconInstagram from '../_svgs/IconInstagram'
import IconLinkedIn from '../_svgs/IconLinkedIn'
import IconTwitter from '../_svgs/IconTwitter'
import AnimateSplitText from '../_animation/AnimateSplitText'
import AnimateSlideIn from '../_animation/AnimateSlideIn'
import { TextBodySmall } from '../TextStyles'
import {
  FooterRow,
  FooterEmail,
  FooterSocialLink,
  FooterLinkText,
  FooterMain,
  FooterSocial,
  FooterLinks,
} from './index.style'
import { ContextDispatch } from '../../context/Context'

const Footer = () => {
  const {
    allContentfulGlobals: {
      nodes: [
        {
          contactEmail,
          instagramUrl,
          twitterUrl,
          linkedInUrl,
          instagramVisibility,
          twitterVisibility,
          linkedInVisibility,
        },
      ],
    },
    site: {
      siteMetadata: { routes },
    },
  } = useStaticQuery(graphql`
    query FooterQuery {
      allContentfulGlobals {
        nodes {
          contactEmail
          instagramUrl
          instagramVisibility
          twitterUrl
          twitterVisibility
          linkedInUrl
          linkedInVisibility
        }
      }
      site {
        siteMetadata {
          routes {
            privacyPolicy
            termsOfUse
          }
        }
      }
    }
  `)
  const dispatch = useContext(ContextDispatch)

  return (
    <FooterMain>
      <Spacer size={[100, 240]} />

      <Container>
        <Grid>
          <GridItem desk={6} deskStart={2}>
            <FooterRow center>
              <FooterEmail>
                <AnimateSplitText delay={0.1}>{contactEmail}</AnimateSplitText>
              </FooterEmail>

              <FooterSocial>
                {linkedInVisibility && (
                  <FooterSocialLink
                    href={linkedInUrl}
                    target="_blank"
                    onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
                    onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
                  >
                    <AnimateSlideIn delay={0.1}>
                      <IconLinkedIn />
                    </AnimateSlideIn>
                  </FooterSocialLink>
                )}

                {instagramVisibility && (
                  <FooterSocialLink
                    href={instagramUrl}
                    target="_blank"
                    onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
                    onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
                  >
                    <AnimateSlideIn>
                      <IconInstagram />
                    </AnimateSlideIn>
                  </FooterSocialLink>
                )}

                {twitterVisibility && (
                  <FooterSocialLink
                    href={twitterUrl}
                    target="_blank"
                    onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
                    onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
                  >
                    <AnimateSlideIn delay={0.2}>
                      <IconTwitter />
                    </AnimateSlideIn>
                  </FooterSocialLink>
                )}
              </FooterSocial>
            </FooterRow>

            <Spacer size={[44, 66]} />

            <FooterRow>
              <TextBodySmall>
                <AnimateSplitText delay={0.2}>
                  © {new Date().getFullYear()} Vivid Group Holdings, Inc. All
                  rights reserved.
                </AnimateSplitText>
              </TextBodySmall>

              <FooterLinks>
                <FooterLinkText>
                  <PageTransitionLink
                    to={routes.termsOfUse}
                    onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
                    onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
                  >
                    <TextBodySmall>
                      <AnimateSplitText delay={0.1}>
                        Terms of Use
                      </AnimateSplitText>
                    </TextBodySmall>
                  </PageTransitionLink>
                </FooterLinkText>

                <FooterLinkText>
                  <PageTransitionLink
                    to={routes.privacyPolicy}
                    onMouseEnter={() => dispatch({ type: 'OPEN_CURSOR_LINK' })}
                    onMouseLeave={() => dispatch({ type: 'CLOSE_CURSOR_LINK' })}
                  >
                    <TextBodySmall>
                      <AnimateSplitText>Privacy Policy</AnimateSplitText>
                    </TextBodySmall>
                  </PageTransitionLink>
                </FooterLinkText>
              </FooterLinks>
            </FooterRow>
          </GridItem>
        </Grid>
      </Container>

      <Spacer size={[80, 160]} />
    </FooterMain>
  )
}

export default Footer
