import React, { useCallback, useRef, useEffect } from 'react'
import gsap from 'gsap'
import { SplitText as gsapSplitText } from 'gsap/SplitText'
import PropTypes from 'prop-types'
import FontFaceObserver from 'fontfaceobserver'
import { SplitTextWrapper } from './index.style'
import { font } from '../../../styles/vars/font.style'

gsap.registerPlugin(gsapSplitText)

const AnimateSplitText = ({ children, type, delay }) => {
  const content = useRef()
  const splitContent = useRef()
  const childrenArray = children.props ? children.props.children : children
  const ariaLabel =
    typeof children === 'object'
      ? React.Children.toArray(childrenArray)
          .filter(item => (typeof item === 'object' ? '' : item))
          .join('')
      : children

  const build = useCallback(() => {
    splitContent.current = new gsapSplitText(content.current, {
      type: type,
    })

    splitContent.current[type].forEach((item, itemIndex) => {
      item.classList.add('split__mask')
      item.setAttribute('aria-hidden', true)
      item.style.setProperty('--splitTextDelay', `${itemIndex}s`)
      item.innerHTML = `<div class="split__text">${item.innerHTML}</div>`
    })
  }, [type])

  const update = useCallback(() => {
    splitContent.current.revert()
    build()
  }, [build])

  const textRef = useCallback(
    node => {
      if (node !== null && !node.classList.contains('split')) {
        const fontA = new FontFaceObserver(font.primary.family)

        fontA.load().then(
          () => {
            content.current = node
            build()

            setTimeout(() => {
              content.current.classList.add('split')
            }, 10)

            window.addEventListener('resize', update)
          },
          () => {
            console.log('Font is not available')
          }
        )
      }
    },
    [build, update]
  )

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', update)
    }
  }, [update])

  return (
    <SplitTextWrapper ref={textRef} delay={delay} aria-label={ariaLabel}>
      {children}
    </SplitTextWrapper>
  )
}

AnimateSplitText.propTypes = {
  children: PropTypes.node.isRequired,
  delay: PropTypes.number,
  type: PropTypes.oneOf(['lines', 'words', 'chars', 'words,chars']),
}

AnimateSplitText.defaultProps = {
  type: 'lines',
  delay: 0,
}

export default AnimateSplitText
