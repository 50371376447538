import styled, { css } from 'styled-components'
import { clamp } from '../../styles/utils/conversion.style'
import { flow } from '../../styles/utils/functions.style'
import {
  textStylesConfig,
  TextBodyLarge,
  TextBodyStyles,
} from '../../styles/vars/textStyles.style'

export const RichTextMain = styled.div`
  ${flow()}
`

const listStyles = css`
  ${clamp(
    'font-size',
    textStylesConfig.bodySmall.size.min,
    textStylesConfig.bodySmall.size.max
  )}
  list-style: none;
`

export const UnorderedList = styled.ul`
  ${listStyles}
`

export const OrderedList = styled.ol`
  ${listStyles}
`

export const ListItem = styled.li`
  ${TextBodyLarge} {
    position: relative;
    padding-left: 0.75em;
    ${TextBodyStyles}

    :before {
      content: '–';
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  & + & {
    margin-top: 0.5em;
  }
`

export const TextUnderline = styled.span`
  text-decoration: underline;
`
