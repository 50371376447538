import React from 'react'
import PropTypes from 'prop-types'
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import {
  Heading3,
  TextBodyAdvisor,
  TextBodyLarge,
  TextBodySmall,
} from '../TextStyles'
import AnimateSplitText from '../_animation/AnimateSplitText'
import {
  RichTextMain,
  TextUnderline,
  UnorderedList,
  ListItem,
} from './index.style'

const RichText = ({ content, delay, textSize, animate }) => {
  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.UNDERLINE]: text => <TextUnderline>{text}</TextUnderline>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <Heading3>{children}</Heading3>,

      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),

      [BLOCKS.LIST_ITEM]: (node, children) => {
        return <ListItem>{children}</ListItem>
      },

      [BLOCKS.PARAGRAPH]: (node, children) => {
        if (children?.toString().trim() === '') return

        if (textSize === 'large') {
          return animate ? (
            <TextBodyLarge>
              <AnimateSplitText delay={delay}>{children}</AnimateSplitText>
            </TextBodyLarge>
          ) : (
            <TextBodyLarge>{children}</TextBodyLarge>
          )
        }

        if (textSize === 'regular') {
          return animate ? (
            <TextBodyAdvisor>
              <AnimateSplitText delay={delay}>{children}</AnimateSplitText>
            </TextBodyAdvisor>
          ) : (
            <TextBodyAdvisor>{children}</TextBodyAdvisor>
          )
        }

        if (textSize === 'small') {
          return animate ? (
            <TextBodySmall>
              <AnimateSplitText delay={delay}>{children}</AnimateSplitText>
            </TextBodySmall>
          ) : (
            <TextBodySmall>{children}</TextBodySmall>
          )
        }
      },

      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noreferrer">
          {children}
        </a>
      ),
    },

    renderText: text =>
      React.Children.toArray(
        text.split('\n').map((t, i) =>
          i > 0 ? (
            <>
              <br />
              {t}
            </>
          ) : (
            t
          )
        )
      ),
  }

  return <RichTextMain>{renderRichText(content, options)}</RichTextMain>
}

RichText.propTypes = {
  content: PropTypes.object,
  delay: PropTypes.number,
  textSize: PropTypes.oneOf(['small', 'regular', 'large']),
  animate: PropTypes.bool,
}

RichText.defaultProps = {
  textSize: 'large',
  animate: true,
}

export default RichText
